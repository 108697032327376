import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Banners.css';

const Banners = ({ setCurrentBanner }) => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchBanners = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/uploads/images/banner`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
      console.log('Banners recebidos:', response.data); // Debug
      setBanners(response.data || []);
    } catch (error) {
      console.error('Erro ao buscar os banners:', error);
      setBanners([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (banner) => {
    console.log('Banner selecionado para delete:', banner); // Debug
    setSelectedBanner(banner);
    setShowModal(true);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Por favor, selecione um arquivo para enviar.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      await axios.post(`${baseUrl}/uploads/banner`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Banner adicionado com sucesso!');
      setFile(null);
      fetchBanners();
    } catch (error) {
      console.error('Erro ao enviar o banner:', error);
      alert('Erro ao enviar o banner. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const fileName = selectedBanner?.filename?.split('/').pop();

    if (!fileName) {
      console.error('Nome do arquivo do banner não encontrado:', selectedBanner?.filename);
      return;
    }

    try {
      setLoading(true);
      await axios.delete(`${baseUrl}/uploads/${fileName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Banner excluído com sucesso!');
      fetchBanners();
    } catch (error) {
      console.error('Erro ao excluir o banner:', error);
      if (error.response) {
        console.error('Resposta do servidor:', error.response.data);
      }
      alert('Erro ao excluir o banner. Tente novamente.');
    } finally {
      setLoading(false);
      setShowModal(false);
      setSelectedBanner(null);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div className="container">
      <h1>Gerenciar Banners</h1>
      <input
        type="file"
        className="file-input"
        onChange={handleFileChange}
        accept="image/*"
      />
      <button className="upload-button" onClick={handleUpload}>
        Adicionar Banner
      </button>

      {loading && <p>Carregando...</p>}

      <div className="banners-container">
        {banners.map((banner) => (
          <div key={banner.id} className="banner-thumbnail">
            <img src={banner.url} alt="Banner" />
            <button
              className="delete-button"
              onClick={() => handleDeleteClick(banner)}
            >
              X
            </button>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Tem certeza de que deseja excluir este banner?</p>
            <img
              src={selectedBanner.url}
              alt="Banner a ser excluído"
              className="modal-banner"
            />
            <button
              className="confirm-button"
              onClick={handleDelete}
            >
              Confirmar
            </button>
            <button
              className="cancel-button"
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banners;